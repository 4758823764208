import { type MetaFunction } from '@remix-run/node'

export const meta: MetaFunction = () => [
	{ title: 'Barry McGee - Software Engineer' },
]

export default function Index() {
	return (
		<main className="font-poppins grid">
			<div className="grid">
				<h1>How's it going?</h1>
				<img
					src="/img/speaking.jpeg"
					alt="Barry McGee speaking at a conference"
				/>
				<p>
					I'm a Software Engineer with two decades of experience building web
					experiences and user interfaces for products and services such as the
					BBC, Paul Smith, FutureLearn, Kahoot, Unbuntu and currently --
					Shopify.
				</p>
			</div>
		</main>
	)
}
